import React, { useRef, useState, useEffect } from "react";
import { useRouter } from "next/router";
import Link from "next/link";
import { useAuth } from "./../util/auth";
import Meta from "./../components/Meta";
import Vimeo from "@u-wave/react-vimeo";

function IndexPage(props) {
  const [pending, setPending] = useState(false);
  const router = useRouter();
  const auth = useAuth();
  const videoBoxRef = useRef(null);
  const [videoBoxDimensions, setVideoBoxDimensions] = useState({
    width: 0,
    height: 0,
  });
  useEffect(() => {
    function handleWindowResize() {
      setVideoBoxDimensions({
        width: videoBoxRef.current.offsetWidth,
        height: videoBoxRef.current.offsetHeight,
      });
    }

    handleWindowResize();

    window.addEventListener("resize", handleWindowResize);

    return () => {
      window.removeEventListener("resize", handleWindowResize);
    };
  }, []);

  const callToAction = auth.user ? (
    <button
      type="submit"
      className="btn btn-primary"
      onClick={() => {
        router.push("/new");
      }}
      disabled={pending}
    >
      {pending ? "..." : "Generate"}
    </button>
  ) : (
    <>
      <Link href="/auth/signin" shallow={true} className="btn btn-primary">
        Get Started
      </Link>
      <p className="mt-2 text-xs text-gray-500">
        No risk. No credit card required.
      </p>
    </>
  );

  return (
    <>
      <Meta />

      <div className="hero py-32 md:py-0 md:px-20 md:min-h-screen bg-base-300">
        <div className="hero-content flex-col text-center md:flex-row md:justify-between md:text-left">
          <h1 className="basis-2/3 text-3xl md:text-7xl font-bold dark:text-slate-200">
            Turn your thoughts
            <br />
            into an{" "}
            <span className="bg-clip-text text-transparent bg-gradient-to-r from-cyan-300 to-violet-500">
              incredible story
            </span>
            <br />
            in seconds.
          </h1>
          <div className="basis-1/4">
            <p className="pb-6 text-lg">
              Elaborate.ai generates incredible narratives and slides from your
              story outline.
            </p>
            {callToAction}
          </div>
        </div>
      </div>

      <div className="hero py-16 px-4 md:px-20">
        <div className="hero-content flex-col-reverse md:flex-row w-full justify-between">
          <div className="basis-1/3 flex flex-col w-full">
            <div className="grid h-20 card rounded-box">
              <h2 className="card-title text-gray-300">🤖 AI-Powered</h2>
              Elaborate.ai transforms your ideas into polished narratives and
              slides.
            </div>

            <div className="divider"></div>

            <div className="grid h-20 card rounded-box">
              <h2 className="card-title text-gray-300">📖 Idea Development</h2>
              Begin your story at any desired starting point: topic, outline, or
              plot.
            </div>

            <div className="divider"></div>

            <div className="grid h-20 card rounded-box">
              <h2 className="card-title text-gray-300">🔧 Fine-Tuning</h2>
              Fine-tune the generated content iteratively, step by step.
            </div>
          </div>

          <div
            ref={videoBoxRef}
            className="mockup-window mb-10 shadow-xl border border-neutral-800 bg-base-300 w-full md:basis-1/2"
          >
            {videoBoxDimensions.width > 0 && (
              <Vimeo
                video="799707336"
                className="flex justify-center"
                width={videoBoxDimensions.width}
                height={videoBoxDimensions.width * 0.5625}
                showPortrait={false}
                showTitle={false}
              />
            )}
          </div>
        </div>
      </div>

      <div className="hero py-16 px-4 md:px-20 bg-base-200">
        <div className="hero-content flex-col lg:flex-row">
          <div className="grid md:grid-cols-4 gap-6">
            <div className="card bg-base-100 shadow-lg">
              <figure className="text-9xl h-48 bg-yellow-500">📝</figure>
              <div className="card-body">
                <h2 className="card-title text-gray-300">Content Writing</h2>
                <p>
                  Just write down the outline of your story, and let Elaborate
                  AI do the rest.
                </p>
                <p className="text-sm">
                  e.g.,{" "}
                  <a
                    className="underline"
                    href="https://elaborate.ai/slides/a14ee5f9-4805-4c27-866b-70ae5c5d563e"
                  >
                    Stay Simple and Productive
                  </a>
                </p>
              </div>
            </div>

            <div className="card bg-base-100 shadow-lg">
              <figure className="text-9xl h-48 bg-orange-500">📖</figure>
              <div className="card-body">
                <h2 className="card-title text-gray-300">
                  Research Presentation
                </h2>
                <p>
                  Focus on the key message, and effectively communicate the
                  findings to the audience.
                </p>
                <p className="text-sm">
                  e.g.,{" "}
                  <a
                    className="underline"
                    href="https://elaborate.ai/slides/b24055a9-f4f0-4c38-8716-efc341d5bb5c"
                  >
                    Lifecycle of a Butterfly
                  </a>
                </p>
              </div>
            </div>

            <div className="card bg-base-100 shadow-lg">
              <figure className="text-9xl h-48 bg-lime-500">⏳</figure>
              <div className="card-body">
                <h2 className="card-title text-gray-300">Progress Reports</h2>
                <p>
                  Jot down short progress updates, and share engaging
                  information with stakeholders.
                </p>
                <p className="text-sm">
                  e.g.,{" "}
                  <a
                    className="underline"
                    href="https://elaborate.ai/slides/9db379fd-2278-4db6-ba32-f836d9478dce"
                  >
                    Weekly Progress Report
                  </a>
                </p>
              </div>
            </div>

            <div className="card bg-base-100 shadow-lg">
              <figure className="text-9xl h-48 bg-cyan-500">🎓</figure>
              <div className="card-body text-gray-300">
                <h2 className="card-title">Educational Materials</h2>
                <p>Minimize the time spent on creating lecture materials.</p>
                <p className="text-sm">
                  e.g.,{" "}
                  <a
                    className="underline"
                    href="https://elaborate.ai/slides/8b96671b-43a8-43ed-9784-11bf06e08a82"
                  >
                    Amazon Working Backwords
                  </a>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="hero py-16">
        <div className="hero-content text-center">
          <div className="max-w-md">
            <h1 className="text-4xl font-bold">Begin Your Story Now.</h1>
            <p className="py-6">
              Elaborate.ai generates incredible narratives and slides from your
              story outline.
            </p>
            {callToAction}
          </div>
        </div>
      </div>
    </>
  );
}

export default IndexPage;
